import '../css/cources.css';

function Nopages() {
  return (
    <div>
        404 page not found
    </div>
  );
}

export default Nopages;