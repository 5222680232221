import '../css/home.css';

function Home() {
  return (
    <div className='main-body'>
      <div className="top-bar">
        <div className='row-b'>
            <div className='logo'>TDC</div>
            <div className='row' id='mobilebar'>
                <a href="/" className='top-item'>Home</a>
                <a href="/courses" className='top-item'>Courses</a>
                <a href="/about" className='top-item'>About</a>
                <a href="/contact" className='top-item'>Contact</a>
                <a href="https://form.jotform.com/232102015373036" target='_blank'>
                  <button className='top-join'>Join</button>
                </a>
            </div>
        </div>
      </div>
      <div className='body-1'>
        <div className='row'>
            <div className='half left'>
                <h1 className='head'>Learn, Earn & Grow</h1>
                <p className='sub-head'>Unlock Your Potential! Join TDC, where you’ll master app development, web design, marketing, and more. Learn from experts, clear doubts together, and embrace a community that nurtures personal growth. Together, we’ll elevate our skills and careers!</p>
                <div className='heit-60'></div>
                <div className='row'>
                  <div className='space-20vw'></div>
                  <div className='padd-l'>
                      <div className='row bt-g'>
                          <button className='body-1-btn exp'>Explore</button>
                          <a className='body-1-btn joi' href="https://form.jotform.com/232102015373036" target='_blank'>
                            <button className='body-2-btn'>Join Now</button>
                          </a>
                      </div>
                  </div>
                </div>
            </div>
            <div className='half right'>
              <div className='row-a'>
                <img className='tree' src="https://thumbsnap.com/i/8R5TVhcW.png" alt="" />
              </div>
            </div>
        </div>
      </div>
      <div className='body-2'>
        <div className='height-60'></div>
        <div className='height-60'></div>
        <div className='row-b'>
          <div className='about-head'>"Succeed with a Tech Driven Community: Join TDC Today!"</div>
          <img className='imgg' src="https://thumbsnap.com/i/bUytASzY.jpg" alt="" />
        </div>
        <div className='row'>
          <div className='about-sub-line'></div>
          <div className='about-sub-head'>TDC is a supportive platform for individuals who are passionate about learning and growing their skills in app development, web design, marketing, and more. Our community is dedicated to sharing knowledge and collaborating to help each other reach our full potential. By joining TDC, you'll have the opportunity to learn from experts, clear doubts together, and embrace a community that nurtures personal growth. Together, we'll elevate our skills and careers to new heights. Don't miss out on this chance to unlock your potential - join TDC today!</div>
        </div>
      </div>
      <div className='body-3'>
        <div className='row-e'>
          <div className='qq'></div>
          <div>
            <div className='height-20'></div>
            <div className='a-head'>Our Fairy Tale Begin's here...</div>
          </div>
          <img src="https://thumbsnap.com/i/LSnZfNnF.jpg" className='imtt' alt="" />
        </div>
        <div className='height-60'></div>
        <div className='row-a'>
            <video controls autoFocus src="https://thumbsnap.com/i/jrnojerP.mp4"></video>
        </div>
      </div>
      <div></div>
      <div className='body-4'>
        <div className='height-60'></div>
        <div className='row-a'>
          <div className='row-e'>
            <img className='hands' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe-r5GW4lJwZn7-TbpyHWAU2pPbZDYqLoCSQ&usqp=CAU" alt="" />
            <div className='space-20'></div>
            <div>
              <div className='height-20'></div>
              <div className='about-head-1'>Let's Work Together!</div>
              <div className='height-10'></div>
              <div className='about-sub-head-1'>"Personal Growth , Skills , and Financial Freedom. It's our mission."</div>
              <div className='height-20'></div>
              <div className='row-a'>
                <a href="https://form.jotform.com/232102015373036" target='_blank'>
                  <button className='top-join-1'>Get Started</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='fotter'>
        <div className='row-a'>
          <div className='fotter-text'>Copyright © 2023 TDC | Powered by PYB247</div>
        </div>
      </div>
    </div>
  );
}

export default Home;