import { useState } from 'react';
import '../css/cources.css';
import { useEffect } from 'react';

function Courses() {
  //const [error, setError] = useState(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  //const [items, setItems] = useState([]);


  const [items, setItems] = useState([])
  const fetchUserData = () => {
    fetch("https://jalabiibaai.pythonanywhere.com/get_courses")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setItems(data)
      })
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  //useEffect(() => {
  //  fetchData()
  //}, [])
  //useEffect(() => {
  //  fetch("https://jalabiibaai.pythonanywhere.com/get_courses")
  //    .then(res => res.json())
  //    .then(
  //      (result) => {
  //        setIsLoaded(true);
  //        setItems(result);
  //      },
  //      (error) => {
  //        setIsLoaded(true);
  //        setError(error);
  //      }
  //    )
  //}, [])
//
  //if (error) {
  //  return <div>Error: {error.message}</div>;
  //} else if (!isLoaded) {
  //  return <div>Loading...</div>;
  //}
  //else{
  //  return (
  //  <div className='boddy'>
  //    <div className="top-bar">
  //      <div className='row-b'>
  //          <div className='logo'>TDC</div>
  //          <div className='row'>
  //              <a href="/" className='top-item'>Home</a>
  //              <a href="/courses" className='top-item'>Courses</a>
  //              <a href="/about" className='top-item'>About</a>
  //              <a href="/contact" className='top-item'>Contact</a>
  //              <button className='top-join'>Join</button>
  //          </div>
  //      </div>
  //    </div>
  //    <div className='row-a'>
  //      <div className='c-box'>
  //        <div>
  //          <div className='c-head'>Free Courses</div>
  //          <div className='grid'>
  //          {items.map(item => (
  //            <div className='g-item'>
  //              <div className='row'>
  //                <div className='img-grid'>
  //                  <img src="{item[2]}" alt="" />
  //                </div>
  //                <div>
  //                  <div className='hed'>{item[0]}</div>
  //                  <div className='sed'>{item[1]}</div>
  //                </div>
  //              </div>
  //              <div className='grid-e'>
  //                <a className='enroll-n' href="{item[3]}">Enroll</a>
  //              </div>
  //            </div>
  //            ))}
  //          </div>
  //        </div>
  //      </div>
  //    </div>
  //  </div>
  //);
  return (
    <div className='boddy'>
        <div className="top-bar">
          <div className='row-b'>
              <div className='logo'>TDC</div>
              <div className='row'>
                  <a href="/" className='top-item'>Home</a>
                  <a href="/courses" className='top-item'>Courses</a>
                  <a href="/about" className='top-item'>About</a>
                  <a href="/contact" className='top-item'>Contact</a>
                  <button className='top-join'>Join</button>
              </div>
          </div>
        </div>
        <div className='row-a'>
          <div className='c-box'>
            <div>
              <div className='c-head'>Free Courses</div>
              <div className='grid'>
              {items.map(item => (
                <div className='g-item'>
                  <div className='row'>
                    <div className='img-grid'>
                      <img src={item.img} alt="" />
                    </div>
                    <div>
                      <div className='hed'>{item.hed}</div>
                      <div className='sed'>{item.sub}</div>
                    </div>
                  </div>
                  <div className='grid-e'>
                    <a className='enroll-n' href={item.link}>Enroll</a>
                  </div>
                </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Courses;