import '../css/cources.css';

function About() {
  return (
    <div className='main'>
      <div className="top-bar">
      <div className='row-b'>
          <div className='logo'>TDC</div>
          <div className='row'>
              <a href="/" className='top-item'>Home</a>
              <a href="/courses" className='top-item'>Courses</a>
              <a href="/about" className='top-item'>About</a>
              <a href="/contact" className='top-item'>Contact</a>
              <button className='top-join'>Join</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;