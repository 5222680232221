import ReactDOM from "react-dom/client";
import { BrowserRouter,Routes, Route, Link } from 'react-router-dom';
import Home from "./pages/home";
import NoPage from "./pages/nopage";
import Courses from "./pages/cources";
import Contact from "./pages/contact";
import About from "./pages/about";

export default function App() {
    return ( 
        <BrowserRouter>
           <Routes>
                 <Route exact path='/' element={< Home />}></Route>
                 <Route exact path='/courses' element={< Courses />}></Route>
                 <Route exact path='/about' element={< About />}></Route>
                 <Route exact path='/contact' element={< Contact />}></Route>
                 <Route exact path='/*' element={< NoPage />}></Route>
          </Routes>
       </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);